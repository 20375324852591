import { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Dropzone, ExtFile } from "@dropzone-ui/react";
import { AlertService } from "../../services/alert.service";
import { BlackListService } from "../../services/blacklist.service";
import { ProductsService } from "../../services/products.service";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/header.component";
import Input from "../../components/forms/input.component";
import Button from "../../components/forms/button.component";
import Asaid from "../../components/layout/asaid.component";
import Footer from "../../components/layout/footer.component";
import useBgPages from "../../hooks/bg-pages.hook";
import Select from "react-select";
import InvalidFeedback from "../../components/forms/invalid-feedback.component";
import { CampaignsService } from "../../services/campaings.service";
import tipifications from "./tipifications.json";

function CampaignsPage() {
  useBgPages();
  const navigate = useNavigate();
  const [blacklist, setBlacklist] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [dialerOptions, setDialerOptions] = useState<any>([]);
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
  const defaultValues = {
    name: "",
    file: "",
    blacklists: "",
    product: "",
    dialer: "",
    typifications: [] as { label: string; level: number; children?: any[] }[],
  };

  const {
    register,
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "typifications",
  });

  const file = watch("file");
  const typifications = watch("typifications");

  const sendCampaign = async (data: any) => {
    if (!file) {
      AlertService.toastWarning(
        "¡ Atención !, antes de enviar la campaña debes cargar el archivo TXT.",
      );
      return;
    }

    if (data.typifications.some((item: any) => item.label.trim() === "")) {
      AlertService.toastWarning(
        "¡Atención!, antes de enviar la campaña todas las tipificaciones son requeridas.",
      );
      return;
    }

    try {
      delete data.file_input;
      data.blacklists = Array.isArray(data.blacklists)
        ? data.blacklists.join("|")
        : data.blacklists;
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("product", data.product);
      formData.append("dialer", data.dialer);
      formData.append("blacklists", data.blacklists);
      formData.append("file", data.file);
      formData.append("typifications", JSON.stringify(data.typifications));
      await CampaignsService.uploadCampaign(formData);
      reset(defaultValues);
      AlertService.toastSuccess(
        "¡ Éxito !, La campaña se envío correctamente.",
      );
      navigate("/listCampaigns");
    } catch (error) {
      console.log(error);
      AlertService.toastError("Error al enviar la campaña");
    }
  };

  const updateFiles = (incomingFiles: ExtFile[]) => {
    const allowedExtensions = [".txt"];
    const file = incomingFiles[0]?.file as File | null;

    if (file && allowedExtensions.includes(`.${file.name.split(".").pop()}`)) {
      setValue("file", file as any);
    } else {
      AlertService.toastError("Solo se permiten archivos con extensión .txt");
      setValue("file", "");
    }
  };

  const removeFile = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setValue("file", "");
  };

  const getBlackList = async () => {
    const data = await BlackListService.getBlackLists();
    if (data) {
      const filteredData = data.filter((item: any) => item.status === true);
      const mappedBlacklist = filteredData.map((item: any) => ({
        value: item._id,
        label: item.name,
      }));

      setBlacklist(mappedBlacklist);
    } else {
      setBlacklist([]);
    }
  };

  const getProducts = async () => {
    const data = await ProductsService.getProducts();
    const mappedProducts = data.map((item: any) => ({
      value: item._id,
      label: item.name,
    }));
    setProducts(mappedProducts);
  };

  const getDialers = async () => {
    const data = await CampaignsService.getDialers();
    if (data) {
      const mappedDialers = data.map((item: any) => ({
        value: item.campaign,
        label: item.campaign,
      }));
      setDialerOptions(mappedDialers);
    } else {
      setDialerOptions([]);
    }
  };

  const isValidTypifications = () => {
    const validateChildren = (children: any[]): boolean => {
      return children.every((child) => {
        if (child.label.trim() === "") {
          return false;
        }
        if (child.children && child.children.length > 0) {
          return validateChildren(child.children);
        }
        return true;
      });
    };
    return typifications.every((field: any) => {
      if (field.label.trim() === "") {
        return false;
      }
      if (field.children && field.children.length > 0) {
        return validateChildren(field.children);
      }
      return true;
    });
  };

  const transformTipifications = (tipifications: any[], level = 1): any[] => {
    return tipifications.map((item) => ({
      label: item.label,
      level,
      children: transformTipifications(item.children || [], level + 1),
    }));
  };

  const addSubTypification = (
    parentIndex: number,
    currentLevel: number,
    childIndex?: number,
  ) => {
    const currentTypifications = watch("typifications");
    const parentTypification = currentTypifications[parentIndex];

    if (currentLevel === 1) {
      const updatedChildren = [
        ...(parentTypification.children || []),
        {
          label: "",
          level: 2,
          children: [],
        },
      ];

      update(parentIndex, {
        ...parentTypification,
        children: updatedChildren,
      });
    } else if (currentLevel === 2 && childIndex !== undefined) {
      const level2Typification = parentTypification.children?.[childIndex] || {
        children: [],
      };

      const updatedChildren = [
        ...(level2Typification.children || []),
        {
          label: "",
          level: 3,
        },
      ];

      const updatedLevel2Children =
        parentTypification.children?.map((child: any, index: number) => {
          if (index === childIndex) {
            return {
              ...child,
              children: updatedChildren,
            };
          }
          return child;
        }) || [];
      update(parentIndex, {
        ...parentTypification,
        children: updatedLevel2Children,
      });
    }
  };

  const removeSubTypification = (
    parentIndex: number,
    childIndex?: number,
    subChildIndex?: number,
  ) => {
    const currentTypifications = watch("typifications");

    if (childIndex !== undefined && subChildIndex !== undefined) {
      const parentTypification = currentTypifications[parentIndex];
      const level2Typification = parentTypification.children?.[childIndex];
      const updatedSubChildren =
        level2Typification?.children?.filter(
          (_: any, index: number) => index !== subChildIndex,
        ) || [];
      const updatedLevel2Children =
        parentTypification.children?.map((child: any, index: number) => {
          if (index === childIndex) {
            return {
              ...child,
              children: updatedSubChildren,
            };
          }
          return child;
        }) || [];

      update(parentIndex, {
        ...parentTypification,
        children: updatedLevel2Children,
      });
    } else if (childIndex !== undefined) {
      const parentTypification = currentTypifications[parentIndex];
      const updatedChildren =
        parentTypification.children?.filter(
          (_: any, index: number) => index !== childIndex,
        ) || [];

      update(parentIndex, {
        ...parentTypification,
        children: updatedChildren,
      });
    } else {
      remove(parentIndex);
    }
  };

  const toggleAccordion = (index: number) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({ label: "", level: 1, children: [] });
    }
  }, [append, fields]);

  useEffect(() => {
    const transformedTipifications = transformTipifications(tipifications);
    setValue("typifications", transformedTipifications);
  }, [setValue]);

  useEffect(() => {
    getProducts();
    getBlackList();
    getDialers();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <Header />
          <div
            className="content fs-6 d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">Campañas</li>
                    <li className="breadcrumb-item text-muted"></li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-send fs-2x">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      <span className="ms-2">Envío campañas</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  <form className="form" onSubmit={handleSubmit(sendCampaign)}>
                    <div className="row mb-7">
                      <div className="col-md-12">
                        <div className="card mb-5">
                          <div className="card-header align-items-center border-0 mt-5">
                            <label className="d-flex flex-stack mb-10">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-circle symbol-50px me-6">
                                  <span className="symbol-label bg-light-primary">
                                    <i className="ki-duotone ki-abstract-26 fs-3x text-primary">
                                      <span className="path1" />
                                      <span className="path2" />
                                    </i>
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <h2 className="fw-bold">
                                    Datos generales
                                    <span className="required"></span>
                                  </h2>
                                  <div className="text-muted fw-semibold fs-6">
                                    Ingresa el nombre y el archivo de los número
                                    de destino.
                                  </div>
                                </span>
                              </span>
                            </label>
                            <div className="card-toolbar"></div>
                          </div>
                          <div className="card-body pt-1">
                            <div className="row">
                              <div className="col-md-6 mt-4">
                                {!file ? (
                                  <Dropzone
                                    onChange={updateFiles}
                                    className="dropzone cursor-default"
                                    accept=".txt"
                                    maxFiles={1}
                                  >
                                    <div className="dz-mkssage">
                                      <>
                                        <i className="ki-duotone ki-file-up fs-5x text-primary me-4">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                        <div className="d-flex flex-center flex-grow-1 flex-wrap flex-md-nowrap">
                                          <div className="mb-3 mb-md-0 fw-semibold">
                                            <h5 className="text-gray-900 fw-bold">
                                              Arrastra tu archivo o haz clic
                                              para subirlo.
                                            </h5>
                                            <div className="fs-7 pe-7 text-info">
                                              Carga un archivo TXT.
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </Dropzone>
                                ) : (
                                  <div className="dropzone cursor-default">
                                    <div className="dz-g">
                                      <i className="ki-duotone ki-file-added text-primary fs-5x text-primary me-4">
                                        <span className="path1" />
                                        <span className="path2" />
                                      </i>
                                      <div className="d-flex flex-column align-items-center flex-grow-1">
                                        <div className="mb-3 mb-md-0 fw-semibold text-center">
                                          <h5 className="text-gray-900 fw-bold">
                                            Archivo cargado con éxito.
                                          </h5>
                                          <div className="fs-7 text-info">
                                            {typeof file === "object" &&
                                            file !== null &&
                                            "name" in file
                                              ? (file as File).name
                                              : "No hay archivo cargado"}
                                          </div>
                                        </div>
                                        <a
                                          className="btn btn-danger px-6 text-nowrap btn-sm mt-3"
                                          href="#"
                                          onClick={removeFile}
                                        >
                                          Eliminar
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="fv-row mb-7">
                                  <Input
                                    label={"Nombre de campaña"}
                                    requiredIndicator="required"
                                    placeholder={"Ingresa nombre de campaña"}
                                    errors={errors.name}
                                    {...register("name", { required: true })}
                                  />
                                </div>
                                <div className="fv-row">
                                  <label className="form-label">
                                    BlackList
                                    <span className="fs-8 text-muted ms-1">
                                      (Opcional)
                                    </span>
                                  </label>
                                  <Controller
                                    control={control}
                                    name="blacklists"
                                    rules={{ required: false }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <>
                                        <Select
                                          options={blacklist}
                                          isMulti={true}
                                          noOptionsMessage={() => (
                                            <div className="empty-select">
                                              {"No hay opciones disponibles"}
                                            </div>
                                          )}
                                          className={
                                            error
                                              ? "form-select-custom is-invalid"
                                              : "form-select-custom"
                                          }
                                          classNamePrefix="form-select-custom"
                                          placeholder={
                                            "Selecciona una BlackList"
                                          }
                                          onChange={(selectedOptions) => {
                                            onChange(
                                              selectedOptions
                                                ? selectedOptions.map(
                                                    (option) => option.value,
                                                  )
                                                : [],
                                            );
                                          }}
                                          value={
                                            blacklist.filter((option: any) =>
                                              value?.includes(option.value),
                                            ) || []
                                          }
                                        />
                                        <InvalidFeedback error={error} />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="card mb-5">
                          <div className="card-header align-items-center border-0 mt-5">
                            <label className="d-flex flex-stack mb-10">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-circle symbol-50px me-6">
                                  <span className="symbol-label bg-light-info">
                                    <i className="ki-duotone ki-share fs-3x text-info">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                      <span className="path3"></span>
                                      <span className="path4"></span>
                                      <span className="path5"></span>
                                      <span className="path6"></span>
                                    </i>
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <h2 className="fw-bold">
                                    Tipificaciones
                                    <span className="required"></span>
                                  </h2>
                                  <div className="text-muted fw-semibold fs-6">
                                    Ingresa las tipificaciones que deseas
                                    agregar a la campaña.
                                  </div>
                                </span>
                              </span>
                            </label>
                            <div className="card-toolbar">
                              <a
                                className="btn btn-light-info ms-auto fw-bolder"
                                onClick={() =>
                                  append({
                                    label: "",
                                    level: 1,
                                    children: [],
                                  })
                                }
                              >
                                Agregar Tipificación
                              </a>
                            </div>
                          </div>
                          <div className="card-body pt-1">
                            <div className="fv-row">
                              <div>
                                <div className="d-flex align-items-center mb-8">
                                  <h4 className="ms-3 text-muted">
                                    Total tipificaciones:{" "}
                                    {watch("typifications").length}
                                  </h4>
                                </div>
                                <div className="scroll-y overflow-auto mh-750px px-5">
                                  {fields.map((field, parentIndex) => (
                                    <div key={field.id}>
                                      <div
                                        className="accordion accordion-icon-toggle mb-7"
                                        id={`accordion_${parentIndex}`}
                                      >
                                        <div
                                          className={`accordion-header d-flex justify-content-between align-items-center ${activeAccordion !== parentIndex ? "collapsed" : ""}`}
                                          data-bs-target={`#accordion_${parentIndex}_item`}
                                          aria-expanded="true"
                                        >
                                          <div
                                            className="d-flex align-items-center"
                                            onClick={() => {
                                              toggleAccordion(parentIndex);
                                            }}
                                          >
                                            <span className="accordion-icon">
                                              <i className="ki-duotone ki-arrow-right fs-1">
                                                <span className="path1" />
                                                <span className="path2" />
                                              </i>
                                            </span>
                                            <h4 className="fs-6 fw-semibold mb-0 ms-4 text-primary fw-bolder">
                                              Tipificación
                                            </h4>
                                          </div>

                                          {fields.length > 1 && (
                                            <a
                                              type="button"
                                              className="btn btn-light-danger btn-sm me-7"
                                              onClick={() =>
                                                remove(parentIndex)
                                              }
                                            >
                                              Eliminar
                                            </a>
                                          )}
                                        </div>

                                        <div
                                          id={`accordion_${parentIndex}_item`}
                                          className={`accordion-body collapse ${
                                            activeAccordion === parentIndex
                                              ? "show"
                                              : ""
                                          }`}
                                        >
                                          <div className="card rounded h-100 border border">
                                            <div className="card-body">
                                              {/* Nivel 1 */}
                                              {field.level === 1 && (
                                                <div className="row mb-5">
                                                  <div className="col-10">
                                                    <Input
                                                      label={`Tipificación ${field.level}° Nivel`}
                                                      requiredIndicator="required"
                                                      {...register(
                                                        `typifications.${parentIndex}.label`,
                                                      )}
                                                      placeholder={
                                                        "Ingresa la tipificación"
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-2 d-flex align-items-center">
                                                    <a
                                                      type="button"
                                                      className="btn btn-light-info btn-sm mt-10"
                                                      onClick={() =>
                                                        addSubTypification(
                                                          parentIndex,
                                                          field.level,
                                                        )
                                                      }
                                                    >
                                                      <i className="ki-duotone ki-abstract-10 fs-2x">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                      </i>
                                                    </a>
                                                  </div>
                                                </div>
                                              )}
                                              {/* Renderizar los hijos (Niveles 2 y 3) */}
                                              {field.children?.map(
                                                (child, childIndex) => (
                                                  <div key={childIndex}>
                                                    {/* Nivel 2 */}

                                                    <div className="row mb-5 align-items-center">
                                                      <div className="col-8 ms-10">
                                                        <Input
                                                          label={`Tipificación ${child.level}° Nivel`}
                                                          requiredIndicator="required"
                                                          {...register(
                                                            `typifications.${parentIndex}.children.${childIndex}.label`,
                                                          )}
                                                          placeholder={
                                                            "Ingresa la tipificación"
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-1 d-flex justify-content-center">
                                                        <a
                                                          type="button"
                                                          className="btn btn-light-info btn-sm mt-10 ms-10"
                                                          onClick={() =>
                                                            addSubTypification(
                                                              parentIndex,
                                                              child.level,
                                                              childIndex,
                                                            )
                                                          }
                                                        >
                                                          <i className="ki-duotone ki-abstract-10 fs-2x">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                          </i>
                                                        </a>
                                                      </div>
                                                      <div className="col-1 d-flex justify-content-center">
                                                        <a
                                                          type="button"
                                                          className="btn btn-light-danger btn-sm ms-2 mt-10 ms-20"
                                                          onClick={() =>
                                                            removeSubTypification(
                                                              parentIndex,
                                                              childIndex,
                                                            )
                                                          }
                                                        >
                                                          <i className="ki-duotone ki-trash fs-2x">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                            <span className="path3"></span>
                                                            <span className="path4"></span>
                                                            <span className="path5"></span>
                                                          </i>
                                                        </a>
                                                      </div>
                                                    </div>

                                                    {/* Renderizar hijos de nivel 2 (Niveles 3) */}
                                                    {child.children?.map(
                                                      (
                                                        subChild: any,
                                                        subChildIndex: any,
                                                      ) => (
                                                        <div
                                                          key={subChildIndex}
                                                        >
                                                          {/* Nivel 3 */}

                                                          <div className="row mb-5">
                                                            <div className="col-9 ms-20">
                                                              <Input
                                                                label={`Tipificación ${subChild.level}° Nivel`}
                                                                requiredIndicator="required"
                                                                {...register(
                                                                  `typifications.${parentIndex}.children.${childIndex}.children.${subChildIndex}.label`,
                                                                )}
                                                                placeholder={
                                                                  "Ingresa la tipificación"
                                                                }
                                                              />
                                                            </div>
                                                            <div className="col-1 d-flex align-items-center">
                                                              <a
                                                                type="button"
                                                                className="btn btn-light-danger btn-sm mt-10"
                                                                onClick={() =>
                                                                  removeSubTypification(
                                                                    parentIndex,
                                                                    childIndex,
                                                                    subChildIndex,
                                                                  )
                                                                }
                                                              >
                                                                <i className="ki-duotone ki-trash fs-2x">
                                                                  <span className="path1"></span>
                                                                  <span className="path2"></span>
                                                                  <span className="path3"></span>
                                                                  <span className="path4"></span>
                                                                  <span className="path5"></span>
                                                                </i>
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ),
                                                    )}
                                                  </div>
                                                ),
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card mb-5">
                          <div className="card-header align-items-center border-0 mt-5">
                            <label className="d-flex flex-stack mb-5">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-circle symbol-50px me-6">
                                  <span className="symbol-label bg-light-primary">
                                    <i className="ki-duotone ki-award fs-3x text-primary">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                      <span className="path3"></span>
                                    </i>
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <h2 className="fw-bold">
                                    Producto y Dialer
                                    <span className="required"></span>
                                  </h2>
                                  <div className="text-muted fw-semibold fs-6">
                                    Selecciona el producto y dialer para la
                                    campaña.
                                  </div>
                                </span>
                              </span>
                            </label>
                            <div className="card-toolbar"></div>
                          </div>
                          <div className="card-body pt-1">
                            <div className="fv-row mb-8">
                              <label className="form-label required">
                                Producto
                                <span className="fs-8 text-muted ms-1"></span>
                              </label>
                              <Controller
                                control={control}
                                name="product"
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <Select
                                      options={products}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {"No hay opciones disponibles"}
                                        </div>
                                      )}
                                      className="form-select-custom"
                                      classNamePrefix={"form-select-custom"}
                                      placeholder={"Selecciona un producto"}
                                      onChange={(
                                        selectedOption: {
                                          value: string;
                                          label: string;
                                        } | null,
                                      ) => {
                                        onChange(
                                          selectedOption
                                            ? selectedOption.value
                                            : null,
                                        );
                                      }}
                                      value={
                                        products?.find(
                                          (profileOption: any) =>
                                            profileOption.value === value,
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row">
                              <label className="form-label required">
                                Dialer
                                <span className="fs-8 text-muted ms-1"></span>
                              </label>
                              <Controller
                                control={control}
                                name="dialer"
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <Select
                                      options={dialerOptions}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {"No hay opciones disponibles"}
                                        </div>
                                      )}
                                      className="form-select-custom"
                                      classNamePrefix={"form-select-custom"}
                                      placeholder={"Selecciona un dialer"}
                                      onChange={(
                                        selectedOption: {
                                          value: string;
                                          label: string;
                                        } | null,
                                      ) => {
                                        onChange(
                                          selectedOption
                                            ? selectedOption.value
                                            : null,
                                        );
                                      }}
                                      value={
                                        dialerOptions?.find(
                                          (dialer: any) =>
                                            dialer.value === value,
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end my-10">
                          <div>
                            <div className="me-2">
                              <Button
                                type="submit"
                                typeStepperBtn="submit"
                                id="sendData"
                                className="btn btn-lg btn-primary w-100 mb-5 hover-scale"
                                disabled={
                                  !isValid || !file || !isValidTypifications()
                                }
                                isLoading={isSubmitting}
                              >
                                Enviar campaña
                                <i className="ki-duotone ki-send ms-2 fs-1">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default CampaignsPage;
