import { useEffect, useRef, useState } from "react";
import { AlertService } from "../../services/alert.service";
import { useForm } from "react-hook-form";
import { AgentsService } from "../../services/agent.service";
import useModal from "../../hooks/close-modal.hook";
import Input from "../../components/forms/input.component";
import Button from "../../components/forms/button.component";
import useBgPages from "../../hooks/bg-pages.hook";

interface SecurityQuestion {
  show: boolean;
  onRequestClose: (refresh: boolean, value?: boolean, pm?: any) => void;
  payload: any;
  agent: string;
  registerID: string;
  campaign: string;
}

function SecurityQuestion({
  show,
  onRequestClose,
  payload,
  agent,
  registerID,
  campaign,
}: SecurityQuestion) {
  useBgPages();
  const modalRef = useRef<HTMLDivElement>(null);
  const [meaning, setMeaning] = useState("");
  const [valueQuestion, setValueQuestion] = useState("");
  const [trie, setTries] = useState(0);

  useModal(modalRef, show);

  const defaultValues = {
    question: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const submit = async (data: any) => {
    if (data.question === valueQuestion) {
      AlertService.toastSuccess(
        "¡ Éxito !, respuesta correcta, se paso el filtro de seguridad.",
      );
      onRequestClose(false, true);
      reset(defaultValues);
    } else {
      const newTries = trie + 1;

      if (newTries === 1) {
        AlertService.toastWarning(
          `¡Atención!, Respuesta incorrecta, número de intentos: ${newTries}`,
        );
      }

      if (newTries >= 2) {
        AlertService.toastError(
          "¡Atención!, Se excedió el número de intentos.",
        );
        const formData = {
          campaign: campaign,
          register: registerID,
          agent: agent,
          level_1: "Contacto con cliente",
          level_2: "No acredita filtro de seguiridad",
          level_3: "",
          action: "NO_SECURITY_FILTER",
          time_to_reschedule: "",
          comments: "-",
        };
        await AgentsService.saveForm(formData);
        const message = {
          value1: formData.level_1,
          value2: "",
          value3: "",
          action: formData.action,
          timeToReschedule: "",
        };
        window.parent.postMessage(JSON.stringify(message), "*");
        onRequestClose(false, false, JSON.stringify(message));
        resetForm();
        setTries(0);
        return;
      }

      setTries(newTries);
    }
  };

  const closeModal = () => {
    onRequestClose(false);
    reset(defaultValues);
  };

  const resetForm = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    if (payload) {
      setMeaning(payload.meaning);
      setValueQuestion(payload.value);
    }
  }, [payload]);

  return (
    <div className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => resetForm()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h1 className="mb-3">
                  Filtro de seguridad
                  <a className="link-primary fw-bold ms-2" href="#">
                    {payload?.username}
                  </a>
                </h1>
                <div className="text-muted fw-semibold fs-5">
                  <span>
                    Los campos marcados con <span className="required"></span>{" "}
                    son <a className="link-danger fw-bold">Obligatorios</a>
                  </span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-12 mb-10 mb-lg-0 h-100 p-10 pt-5">
                  <label className="d-flex flex-stack mb-10">
                    <span className="d-flex align-items-center me-2">
                      <span className="symbol symbol-circle symbol-50px me-6">
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-shield fs-3x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <h2 className="fw-bold">Preguntas de seguridad</h2>
                        <div className="text-muted fw-semibold fs-6">
                          Ingesa los datos correspondiente.
                        </div>
                      </span>
                    </span>
                  </label>
                  <div className="fv-row mb-7">
                    <Input
                      label={meaning}
                      requiredIndicator="required"
                      placeholder={`Ingresa fecha nacimiento`}
                      errors={errors.question}
                      {...register("question", {
                        required: true,
                        setValueAs: (value) => value.trim(),
                      })}
                    />
                  </div>
                  <div className="d-flex flex-stack flex-grow-1 ">
                    <div className=" fw-semibold">
                      <h6 className="text-gray-900 fw-bold">
                        Numero de intentos: {trie}
                      </h6>
                      <div className="fs-8 text-gray-700 ">
                        Tienes máximo hasta{" "}
                        <a
                          className="fw-bold"
                          href="/craft/account/billing.html"
                        >
                          2 intentos
                        </a>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => closeModal()}
                >
                  Cerrar
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  onClick={handleSubmit(submit)}
                >
                  Validar
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SecurityQuestion;
